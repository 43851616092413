import * as React from 'react';

export interface ITextFieldUIProps {
    analyticsId: string;
    id: string;
    maxLength: number;
    placeholder: string;
    size?: number;
    updateChange: any;
    validateChange: any;
    value: string;
    autofocus: boolean;
    setRef: any;
    itemKey?: string;
    showMobileNumKeyboard: boolean;
    showLabelFilled: boolean;
    usePaymentRedesign?: boolean;
}

export interface ITextFieldUIState {
    valid: boolean;
    message: string;
    value: string;
    initialValue: string;
}

/*
 * A text field that can handle field level validation and display an error message
 * responsively as the input is changed.
 */
export default class TextFieldUI extends React.Component<ITextFieldUIProps, ITextFieldUIState> {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            message: '',
            value: this.props.value,
            initialValue: this.props.value
        };

        this.updateChange = this.updateChange.bind(this);
        this.createValidationMessage = this.createValidationMessage.bind(this);
    }

    componentDidUpdate() {
        if (!this.state || this.state.initialValue !== this.props.value) {
            this.setState({
                value: this.props.value,
                initialValue: this.props.value
            });
        }
    }

    createValidationMessage() {
        const message = this.state.message;
        if (!message || !message.trim()) {
            return null;
        }

        return (
            <div className="message-error">
                <i className="fa fa-exclamation-circle" aria-hidden="true" /> {message}
            </div>
        );
    }

    // TODO: refactor to setState once
    updateChange(event) {
        const value = event.target.value;
        this.setState({
            value
        });
        
        const { itemKey, updateChange, validateChange } = this.props;
        if (validateChange) {
            const validationResult = validateChange(value, itemKey);
            this.setState(validationResult);
        }

        if (updateChange) {
            updateChange(value, itemKey);
        }
    }

    render() {
        const { analyticsId, id, maxLength, placeholder, size, autofocus } = this.props;
        const value = this.state.value;
        const message = this.createValidationMessage();
        const labelText = this.props.showLabelFilled ? placeholder : '';
        const formControlClass = this.props.showLabelFilled ? 'form-control' : '';
        const inputStyle = message ? ' input-error' : '';
        const type = this.props.showMobileNumKeyboard ? 'tel' : 'text';

        return (
            <div className={`form-group${inputStyle}`}>
                <div className={this.props.usePaymentRedesign ? '' : 'has-float-label'}>
                    <input
                        id={id}
                        type={type}
                        value={value}
                        maxLength={maxLength}
                        size={size}
                        onChange={this.updateChange}
                        placeholder={placeholder}
                        data-analytics={analyticsId}
                        autoFocus={autofocus || false}
                        ref={this.props.setRef}
                        className={formControlClass}
                    />
                    {this.props.usePaymentRedesign ? null : <label className="title"> {labelText} </label>}
                </div>
                {message}
            </div>
        );
    }
}
