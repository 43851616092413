import * as React from 'react';
import { capitalize, IConfigObj } from '@makemydeal/dr-common-utils';
import { IconFactory } from '../../IconFactory/IconFactory';
import { CardContainer } from '../styledCardContainer';

export interface ICardUIProps {
    staticImages: IConfigObj;
    showDealSummary: { (pageId: string) };
    navFlow: any;
    offerType: string;
    isEditDisabled: boolean; // read-only vs non-readonly dashboard (e.g. Shopper Quote)
    isEditButtonAvailable?: boolean; // activity that can't be modifed on non-readonly dashboard (e.g. Reservation on FinishMyDeal)
    isCreditDecisionEnabled?: boolean;
    rightArrowFix: { () };
    onShowDetails: { (cardTitle: string) };
    onHideDetails: { (cardTitle: string) };
    onEditDetails: { (cardTitle: string, route: string) };
    onShowCreditDecisionDetails: { (route: string) };
}

export interface ICompletedCardUIState {
    expanded: boolean | undefined;
}

export class CompletedCard extends React.Component<ICardUIProps, ICompletedCardUIState> {
    constructor(props) {
        super(props);

        this.state = {
            expanded: undefined
        };
    }

    toggleExpanded = () => {
        const { name } = this.props.navFlow;

        this.setState({
            expanded: !this.state.expanded
        });
        this.props.rightArrowFix();

        if (this.state.expanded) {
            this.props.onHideDetails(name);
        } else {
            this.props.onShowDetails(name);
        }
    };

    handleClick = (event) => {
        this.props.onEditDetails(this.props.navFlow.name, event.currentTarget.dataset.route);
    };
    showCreditDecisionDetails = (event) => {
        this.props.onShowCreditDecisionDetails(event.currentTarget.dataset.route);
    };

    showDealSummaryWrapper = (e) => {
        const pageId = e.target.dataset.name ? `${(e.target.dataset.name || '').toUpperCase()}CARD` : 'DASHBOARDCARD';
        this.props.showDealSummary(pageId);
    };

    render() {
        const { navCard, name } = this.props.navFlow;
        const Details = navCard.detailComponent;
        const Summary = navCard.summaryComponent;
        const { expanded } = this.state;
        const { staticImages } = this.props;
        const { offerType, isEditDisabled, isEditButtonAvailable, isCreditDecisionEnabled } = this.props;
        const isCreditAppCard = name === 'creditApp';
        let cardClass = '';
        let drawerClass = '';
        const notEditableClass = isEditDisabled ? 'not-editable' : '';
        const showCreditDecisionExperience = isCreditDecisionEnabled && isCreditAppCard;

        if (expanded === true) {
            cardClass = 'expanded';
            drawerClass = 'opened';
        } else if (expanded === false) {
            cardClass = 'contracted';
            drawerClass = 'closed';
        }
        // NOTE: Using a local variable name as an alias here to make the logic easier to understand.
        const activitiesAreEditable = !isEditDisabled;
        const editButtonElts =
            activitiesAreEditable && !showCreditDecisionExperience && isEditButtonAvailable !== false ? (
                <div
                    className="edit-icon primary-link-container"
                    data-route={this.props.navFlow.defaultRoute}
                    onClick={this.handleClick}
                >
                    <IconFactory staticImages={staticImages} icon="pencilIcon" className="primary-link-icon" />
                    <span className="primary-link">Edit</span>
                </div>
            ) : null;
        const bannerElts = activitiesAreEditable ? (
            <div className="completed-header">
                <span className="completed-header-text common-header">Completed</span>
                <IconFactory staticImages={staticImages} icon="checkCircle" />
            </div>
        ) : null;
        return (
            <CardContainer>
                <div
                    // eslint-disable-next-line max-len
                    className={`dashboard-card-container completed-card-container skinny-card-container fadeInUp ${notEditableClass} ${cardClass}`}
                    data-name={name}
                    data-testid="completed-card"
                >
                    <div className="skinny-card dashboard-card">
                        {bannerElts}
                        <div className="completed-card-body">
                            <div className="completed-card-flex">
                                <div className="card-title common-header">
                                    {navCard.title}
                                    <div className="card-price-mobile card-price">{Summary ? <Summary /> : 'No summary'}</div>
                                </div>
                                <IconFactory
                                    staticImages={staticImages}
                                    icon={navCard.dashboardIcon}
                                    className={activitiesAreEditable ? '' : 'not-editable-icon'}
                                />
                                <div className="card-price-desktop card-price">{Summary ? <Summary /> : 'No summary'}</div>
                            </div>
                            <div className="completed-view primary-link-container" onClick={this.toggleExpanded}>
                                <div className="completed-view-text primary-link">{expanded ? 'Hide' : 'View'}</div>
                                <IconFactory
                                    staticImages={staticImages}
                                    icon={expanded ? 'minusCircle' : 'plusCircle'}
                                    className="primary-link-icon"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`card-drawer ${drawerClass}`}>
                        <div className="card-drawer-body">
                            {editButtonElts}

                            <div className="card-drawer-details">
                                {Details ? <Details /> : <h3 className="common-header">No details yet</h3>}
                            </div>
                        </div>
                        <div className="card-drawer-footer">
                            <div className="primary-link-container">
                                {showCreditDecisionExperience ? (
                                    <a
                                        className="show-credit-decision-details primary-link"
                                        data-route={this.props.navFlow.defaultRoute}
                                        onClick={this.showCreditDecisionDetails}
                                    >
                                        View Details
                                        <IconFactory
                                            staticImages={staticImages}
                                            icon="arrowRight"
                                            className="arrow-right primary-link-icon"
                                        />
                                    </a>
                                ) : (
                                    <a
                                        className="show-breakdown primary-link"
                                        data-name={name}
                                        onClick={this.showDealSummaryWrapper}
                                    >
                                        Full <span className="proper-name">{capitalize(offerType)}</span> Breakdown
                                        <IconFactory
                                            staticImages={staticImages}
                                            icon="arrowRight"
                                            className="arrow-right primary-link-icon"
                                        />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </CardContainer>
        );
    }
}
