import { Reducer } from 'redux';
import { NavigationActions } from './actions';
import { INavigationState } from '../../types/INavigationState';

export const initialState: INavigationState = {
    routeIntent: '',
    headerPosition: null
};

export const navigationReducer: Reducer<INavigationState> = (state: INavigationState = initialState, action: any) => {
    switch (action && action.type) {
        case NavigationActions.NAVIGATE_TO: {
            return { ...state, routeIntent: action.payload };
        }
        case NavigationActions.UPDATE_HEADER_POSITION: {
            return { ...state, headerPosition: action.payload };
        }
        default: {
            return state;
        }
    }
};
