import * as React from 'react';
import { IconFactory } from '../IconFactory/IconFactory';
import { IConfigObj } from '@makemydeal/dr-common-utils';
import { ToastMessageContainer } from './styledToastMessage';

export interface IToastMessageProps {
    staticImages: IConfigObj;
    clickClose: () => any;
    children: React.ReactNode;
}

export const ToastMessage: React.FC<IToastMessageProps> = (props: IToastMessageProps) => {
    const { staticImages, clickClose, children } = props;

    return (
        <ToastMessageContainer>
            <div className="toast-message-outer-container">
                <div className="toast-message-inner-container">
                    <div className="exclamation-circle">
                        <IconFactory staticImages={staticImages} icon="exclamationCircle" />
                    </div>
                    <div data-testid="toast-message">{children}</div>
                    <div className="close-x" onClick={clickClose}>
                        <IconFactory staticImages={staticImages} icon="closeX" />
                    </div>
                </div>
            </div>
        </ToastMessageContainer>
    );
};
