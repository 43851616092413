import * as React from 'react';
import { Component } from 'react';

export interface ILoadingButtonUIStateProps {
    buttonText: string;
    sendingText?: string;
    disabled: boolean;
    isPending: boolean;
    additionalClassParams?: string;
    id?: string;
    isSubmit?: boolean;
}

export interface ILoadingButtonUIDispatchProps {
    onClick?: { () };
}

export interface ILoadingButtonUIProps extends ILoadingButtonUIStateProps, ILoadingButtonUIDispatchProps {}

export interface ILoadingButtonUIState {}

export default class LoadingButtonUI extends Component<ILoadingButtonUIProps, ILoadingButtonUIState> {
    static propTypes = {
        validateOnClick(props) {
            if (props.isSubmit !== true && props.onClick === undefined) {
                return new Error('Please provide a onClick function when isSubmit is not true');
            }
            return;
        }
    };

    handleClick = () => {
        if (this.props.onClick) this.props.onClick();
    };

    render() {
        const { sendingText, id, isSubmit, disabled, isPending, buttonText, additionalClassParams } = this.props;

        const continueButtonLoadingIcon = (
            <div className="dr-ui-loading-button-group">
                <span className="dr-ui-loading-button-form-submiting-indicator-text">{sendingText || 'Sending'}</span>
                <div className="dr-ui-loading-button-spinner"></div>
            </div>
        );

        return (
            <button
                disabled={disabled || isPending}
                className={`btn btn-primary ${additionalClassParams}`}
                id={id || 'dr-ui-loading-button'}
                type={isSubmit ? 'submit' : 'button'}
                onClick={this.handleClick}
            >
                {isPending ? continueButtonLoadingIcon : buttonText}
            </button>
        );
    }
}
