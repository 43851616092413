import styled from 'styled-components';

export const ToastMessageContainer = styled.div`
    .toast-message-outer-container {
        display: flex;
        justify-content: center;

        .toast-message-inner-container {
            background-color: rgb(90, 90, 90);
            color: white;
            padding: 10px;
            border-radius: 5px;
            max-width: 500px;
            font-size: 11px;
            display: flex;
            align-items: center;

            .exclamation-circle {
                svg {
                    height: 25px;

                    [id^='exclamation-circle'] {
                        fill: white;
                    }
                }
            }

            .close-x {
                svg {
                    height: 13px;

                    [id^='close-x'] {
                        fill: white;
                    }
                }
            }
        }
    }
`;
