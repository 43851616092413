import * as React from 'react';

export interface ISelectUIProps {
    options: { id: string; displayValue: string }[];
    selectedOptionId?: string;
    id: string;
    analyticsId: string;
    onSelectionChange?: { (option: any) };
    notAvailableText?: string;
}

interface ISelectUIState {
    selectedOptionId?: string;
    initialSelectedOptionId?: string;
}

class SelectUI extends React.Component<ISelectUIProps, ISelectUIState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedOptionId: this.props.selectedOptionId,
            initialSelectedOptionId: this.props.selectedOptionId
        };
    }

    componentDidUpdate() {
        if (!this.state || this.state.initialSelectedOptionId !== this.props.selectedOptionId) {
            this.setState({
                selectedOptionId: this.props.selectedOptionId,
                initialSelectedOptionId: this.props.selectedOptionId
            });
        }
    }

    render() {
        const { options, id, analyticsId, onSelectionChange, notAvailableText } = this.props;

        let selectItems: any;
        let itemCount: number;

        if (!options) {
            selectItems = null;
            itemCount = 0;
        } else {
            selectItems = options.map((opt) => (
                <option id={opt.id} key={opt.id} value={opt.id}>
                    {opt.displayValue}
                </option>
            ));
            itemCount = selectItems.length;
        }

        const showNotAvailableText = itemCount === 0 && !!notAvailableText && notAvailableText !== '';

        const handleChange = (event) => {
            const newId = event.target.options[event.target.selectedIndex].id;
            this.setState({
                selectedOptionId: newId
            });

            if (typeof onSelectionChange === 'function') {
                onSelectionChange(newId);
            }
        };

        const overlayComponent = !showNotAvailableText ? null : <div className="overlay not-available">{notAvailableText}</div>;

        return (
            <div className="selectui-container">
                <select
                    className="form-control"
                    id={id}
                    value={this.state.selectedOptionId || ''}
                    data-analytics={analyticsId}
                    onChange={handleChange}
                >
                    {selectItems}
                </select>
                {overlayComponent}
            </div>
        );
    }
}

export default SelectUI as any;
