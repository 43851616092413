import { NavigationActions } from './actions';

export const navigateTo = (route: string) => {
    return {
        type: NavigationActions.NAVIGATE_TO,
        payload: route
    };
};

export const updateHeaderPosition = (position: string) => {
    return {
        type: NavigationActions.UPDATE_HEADER_POSITION,
        payload: position
    };
};

export const handleGetDealLinkClicked = () => {
    return {
        type: NavigationActions.DISPLAY_SHARE_DEAL_MODAL
    };
};

export const handleOpenDealerDealModal = () => ({
    type: NavigationActions.SHOW_DEALER_DEAL_MODAL
});

export const handleAskAQuestionLinkClicked = () => {
    return {
        type: NavigationActions.SHOW_ASK_A_QUESTION_MODAL
    };
};
