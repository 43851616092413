/* eslint-disable max-len */
import styled from 'styled-components';
import { rem } from 'polished';
import { BreakPoint } from '../../types/BreakPoint';

const cardWidth = '148px';
const breakpoint = BreakPoint.SCREEN_SM_MIN;
const mediumBreakpoint = BreakPoint.SCREEN_MD_MIN; // is 789px but might need to be 800px?

export const CardContainer = styled.div`
    @media (max-width: ${breakpoint}) {
        width: 100%;
    }
    .dashboard-card-container {
        position: relative;
        margin: ${rem(12)};
        border: ${({ theme }) => theme.dashboardCardContainerBorder};

        // Fixes the collapsed right padding issue with horizontal scrolling
        // https://blog.alexandergottlieb.com/overflow-scroll-and-the-right-padding-problem-a-css-only-solution-6d442915b3f4
        &:last-child::after {
            content: '';
            display: block;
            position: absolute;
            right: ${rem(-64)};
            width: ${rem(64)};
            height: 1px;
        }

        @media (max-width: ${breakpoint}) {
            width: 100%;
            min-width: auto;
            height: auto;
            margin: ${rem(12)} 0;
        }
    }

    .card-container-parent {
        display: flex;
        position: relative;

        &.is-complete {
            .card-flex-container {
                width: 272px;
                margin: 169px 0 76px 76px;
            }

            .card-container {
                align-items: center;
                align-self: flex-end;
                position: relative;
                overflow: visible;
                width: 196px;
                height: 244px;
                padding: 0;
                margin-right: 76px;

                .dashboard-card-container {
                    position: absolute;
                    right: 46px;
                    margin: 0;

                    .skinny-card {
                        box-shadow: none;
                    }

                    &:nth-last-of-type(2),
                    &:nth-last-of-type(3),
                    &:nth-last-of-type(4) {
                        .skinny-card {
                            box-shadow: ${({ theme }) => theme.dashboardSkinnyCardBoxShadow};
                        }
                    }

                    &:nth-last-of-type(2) {
                        right: 0;
                    }

                    &:nth-last-of-type(3) {
                        right: 23px;
                    }
                }
            }
        }

        @media (max-width: ${breakpoint}) {
            flex-direction: column;
        }

        @media (max-width: ${mediumBreakpoint}) {
            &.is-complete {
                flex-direction: column;

                .card-flex-container {
                    width: 100%;
                    margin: ${rem(24)} 0 0;
                }

                .card-container {
                    width: 100%;
                    height: 192px;
                    margin: 0;

                    .dashboard-card-container {
                        width: auto;
                        max-width: 400px;
                        margin: auto;
                        left: 24px;
                        right: 24px;
                        bottom: 48px;

                        &:nth-last-of-type(2) {
                            right: 24px;
                            bottom: 0;
                            z-index: 4;
                        }

                        &:nth-last-of-type(3) {
                            right: 24px;
                            bottom: 16px;
                            z-index: 3;
                        }

                        &:nth-last-of-type(4) {
                            bottom: 32px;
                            z-index: 2;
                        }
                    }
                }

                .skinny-card-container {
                    width: 100%;
                    min-width: auto;
                    height: 144px;
                }

                .skinny-card {
                    height: auto;
                }

                .completed-card-flex {
                    flex-direction: row;
                    text-align: left;
                    height: auto;
                }

                .not-started-card-desktop {
                    height: 100%;
                }
            }
        }
    }

    .card-flex-container {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        position: relative;
        width: 100%;

        transition: width 400ms ease-in-out;
        will-change: width;
    }

    .card-container {
        display: flex;
        align-items: flex-start;
        flex: 0 0 auto;
        overflow-y: hidden;
        overflow-x: hidden;
        padding: ${rem(64)} ${rem(64)} ${rem(8)};
        box-sizing: border-box;
        width: 100%;

        @media (max-width: ${breakpoint}) {
            flex-direction: column;
            padding: ${rem(36)} ${rem(24)} ${rem(8)};
        }
    }

    .deal-complete-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        cursor: pointer;

        @media (max-width: ${mediumBreakpoint}) {
            left: 24px;
            right: 24px;
        }
    }

    .alternate-content-container {
        display: block;
        padding-left: ${rem(10)};
        padding-bottom: ${rem(10)};
        overflow: hidden;
        max-height: 100%;

        &.is-hidden {
            display: none;
        }

        @media (max-width: ${breakpoint}) {
            padding: 0;
        }
    }

    .back-to-dashboard-arrow {
        position: absolute;
        left: 100%;
        margin-left: ${rem(24)};
        cursor: pointer;

        path {
            fill: ${({ theme }) => theme.dashboardDrActivityDashboardBackToDashboardArrowPathFill};
        }

        @media (max-width: ${mediumBreakpoint}) {
            transform: translate(-50%, -12px) rotate(90deg);
            left: 50%;
            top: 100%;
            margin: 0;
        }
    }

    .skinny-card-container {
        width: ${cardWidth};
        min-width: ${cardWidth};

        @media (max-width: ${breakpoint}) {
            width: 100%;
            min-width: auto;
        }
    }

    .completed-card-container {
        @media (min-width: ${breakpoint}) {
            &.expanded {
                animation-duration: 0.5s;
                animation-name: expand;
                animation-fill-mode: both;
            }

            &.contracted {
                animation-duration: 0.5s;
                animation-name: contract;
                animation-delay: 0.5s;
                animation-fill-mode: both;
            }
        }
    }

    .skinny-card {
        position: relative;
        background-color: ${({ theme }) => theme.dashboardDrActivityDashboardSkinnyCardBackgroundColor};
        box-shadow: ${({ theme }) => theme.dashboardSkinnyCardBoxShadow};
        border-radius: ${({ theme }) => theme.dashboardSkinnyCardBorderRadius};
        height: 244px;
        box-sizing: border-box;
        z-index: 2;

        @media (max-width: ${breakpoint}) {
            height: auto;
        }
    }

    .completed-header {
        display: flex;
        align-items: center;
        justify-content: center;

        background: ${({ theme }) => theme.dashboardDrActivityDashboardCompletedHeaderBackgroundColor};
        padding: 0.5rem;
        border-radius: ${({ theme }) => theme.dashboardCompletedHeaderBorderRadius};

        .completed-header-text {
            color: ${({ theme }) => theme.dashboardDrActivityDashboardCompletedHeaderTextColor};
            font-size: ${rem(14)};
            margin-right: ${rem(5)};
        }

        .isvg {
            &.icon-checkCircle {
                g {
                    circle {
                        fill: ${({ theme }) => theme.completedCheckmarkColor};
                    }
                    path {
                        fill: ${({ theme }) => theme.completedCheckmarkBackground};
                    }
                }
            }
        }
    }

    .completed-card-body {
        text-align: center;
        padding: 14px 16px;
    }

    .completed-view {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: ${rem(20)} 0 ${rem(10)} 0;
        cursor: pointer;

        @media (max-width: ${breakpoint}) {
            margin: 0;
        }

        .isvg {
            line-height: 0;
        }

        &:hover {
            svg {
                g {
                    g {
                        stroke: ${({ theme }) => theme.dashboardDrActivityDashboardHoverBlue};
                    }
                }
            }
        }
    }

    .completed-view-text {
        font-size: ${rem(16)};
        margin-right: ${rem(5)};
    }

    .completed-card-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        height: 130px;

        .isvg,
        svg {
            height: 40px;
        }

        .card-price {
            font-size: ${rem(16)};

            .monthly-payment {
                font-weight: 700;
            }
        }

        .card-price-mobile {
            display: none;
        }

        .card-price-desktop {
            display: block;
        }

        @media (max-width: ${breakpoint}) {
            flex-direction: row;

            text-align: left;
            height: auto;

            .card-price-mobile {
                display: block;
                margin-top: ${rem(9)};
            }

            .card-price-desktop {
                display: none;
            }
        }
    }

    .card-title {
        font-size: 16px;

        @media (min-width: ${breakpoint}) {
            font-size: ${rem(18)};
        }
    }

    .save-time {
        font-size: ${rem(14)};
    }

    .time-saved {
        font-size: ${rem(16)};
        font-weight: 600;
        color: ${({ theme }) => theme.dashboardDrActivityDashboardTimeSavedColor};
    }

    .not-started-card-desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        text-align: center;
        padding: ${rem(15)};

        .isvg,
        svg {
            height: 40px;
        }
    }

    .not-started-start-button {
        height: 32px;
        width: 100%;
        cursor: pointer;

        @media (max-width: ${breakpoint}) {
            width: 42%;
        }

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .unavailable-button {
        height: 32px;
        border-radius: ${({ theme }) => theme.dashboardUnavailableButtonBorderRadius};
        border: solid ${rem(1)} transparent;
        margin-top: ${rem(17)};
        width: 100%;
        cursor: pointer;
        background-color: transparent;
        @media (max-width: ${breakpoint}) {
            width: 42%;
        }

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: ${rem(14)};
        font-weight: 600;
        color: ${({ theme }) => theme.dashboardDrActivityDashboardDisabledButtonBlue};
    }

    .not-started-body-item {
        width: 100%;
        margin-bottom: ${rem(25)};
    }

    .not-started-card-mobile {
        background-color: ${({ theme }) => theme.dashboardDrActivityDashboardNotStartedCardMobileBackgroundColor};
        box-shadow: ${({ theme }) => theme.dashboardSkinnyCardBoxShadow};
        border-radius: ${({ theme }) => theme.dashboardNotStartedCardMobileBorderRadius};
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: ${rem(12)} ${rem(16)} ${rem(16)} ${rem(16)};

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .isvg,
        svg {
            height: 40px;
        }

        .not-started-header-mobile {
            display: flex;
            justify-content: space-between;
        }

        .not-started-footer-mobile {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: auto;
        }

        .short-description {
            font-size: 12px;
            color: ${({ theme }) => theme.dashboardDrActivityDashboardShortDescription};
            margin-top: ${rem(8)};
        }

        .shift-title-mobile {
            margin-top: ${rem(4)};
        }
    }

    .carousel-button {
        position: absolute;
        top: 0;
        bottom: 0;
        width: ${rem(80)};
        cursor: pointer;
        z-index: 3;
        transition: width 0.3s;

        &:hover {
            width: ${rem(100)};
        }

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left-arrow {
        left: 0;
        background: linear-gradient(
            90deg,
            ${({ theme }) => theme.dashboardDrActivityDashboardLeftArrowBackgroundColor1} 0%,
            ${({ theme }) => theme.dashboardDrActivityDashboardLeftArrowBackgroundColor2} 100%
        );
    }

    .right-arrow {
        right: 0;
        background: linear-gradient(
            90deg,
            ${({ theme }) => theme.dashboardDrActivityDashboardRightArrowBackgroundColor1} 0%,
            ${({ theme }) => theme.dashboardDrActivityDashboardRightArrowBackgroundColor2} 100%
        );
    }

    @media (max-width: ${breakpoint}) {
        .left-arrow,
        .right-arrow {
            display: none;
        }
    }

    .card-drawer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: ${({ theme }) => theme.dashboardDrActivityDashboardCardDrawerBackgroundColor};
        font-size: ${rem(14)};
        border-bottom-left-radius: ${({ theme }) => theme.dashboardCardDrawerBorderBottomLeftRadius};
        border-bottom-right-radius: ${({ theme }) => theme.dashboardCardDrawerBorderBottomRightRadius};
        color: ${({ theme }) => theme.dashboardDrActivityDashboardCardDrawerColor};
        overflow-y: hidden;
        border: none;
        height: 0;
        z-index: 1;

        .card-drawer-body {
            position: relative;
            padding: ${rem(24)} ${rem(24)} 0;
            flex: 1;

            .edit-icon {
                position: absolute;
                top: 40px;
                right: 25px;
                cursor: pointer;
            }
        }

        .card-drawer-footer {
            padding: 0 1.4rem 1.4rem 1.4rem;
        }

        .show-breakdown {
            font-size: ${rem(16)};
        }

        &.opened {
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-name: slideDown;
            animation-delay: 0s;
            overflow-y: hidden;

            @media (min-width: ${breakpoint}) {
                animation-delay: 0.5s;
            }
        }

        &.closed {
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-name: slideUp;
            overflow-y: hidden;
            border: none;
            height: 0;
        }

        .card-drawer-details h3 {
            font-size: ${rem(16)};
            font-weight: ${({ theme }) => theme.dashboardCardDrawerDetailsHeaderFontWeight};
            margin-bottom: ${rem(11)};
        }

        .card-drawer-list {
            list-style-type: none;
            padding: 0;
            margin: 0 0 ${rem(7)} 0;
            font-size: ${rem(14)};
            line-height: 28px;
            font-weight: 400;
        }
    }

    @keyframes expand {
        from {
            width: ${cardWidth};
            min-width: ${cardWidth};
        }

        to {
            width: ${rem(319)};
            min-width: ${rem(319)};
        }
    }

    @keyframes contract {
        from {
            width: ${rem(319)};
            min-width: ${rem(319)};
        }

        to {
            width: ${cardWidth};
            min-width: ${cardWidth};
        }
    }

    @keyframes slideDown {
        from {
            height: 0;
        }

        to {
            height: 235px;
        }
    }

    @keyframes slideUp {
        from {
            height: 235px;
        }

        to {
            height: 0;
        }
    }

    .fadeInUp {
        animation-name: fadeInUp;
        animation-duration: 500ms;
        animation-timing-function: ease-in-out;
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 5%, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .hr-divider {
        height: 1px;
        margin: ${rem(24)} ${rem(24)} ${rem(24)} 0;
        border-bottom: 1px solid ${({ theme }) => theme.dashboardDrActivityDashboardHrDividerBorderBottom};
        @media (max-width: ${breakpoint}) {
            &:not(.always-visible) {
                display: none;
            }
        }
    }

    .secondary-cards {
        padding: 0;

        .divider {
            margin: 0 ${rem(25)};
            height: 1px;
            border-bottom: 1px solid ${({ theme }) => theme.dashboardDrActivityDashboardSecondaryCardsDividerBorderBottom};
        }

        .divider:last-child {
            display: none;
        }

        @media (min-width: ${breakpoint}) {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0 ${rem(56)};

            .divider {
                margin: 0;
                width: 1px;
                height: ${rem(112)};
                align-self: center;
                flex: 0 0 1px;
                border-bottom: none;
                border-left: 1px solid
                    ${({ theme }) => theme.dashboardDrActivityDashboardSecondaryCardsDividerBorderLeftMediaDesktop};
            }
        }
    }

    .next-steps-card {
        height: 280px;

        @media (min-width: ${breakpoint}) {
            position: relative;
            width: ${rem(398)};
            min-width: ${rem(398)};
        }
    }

    .arrow-right {
        margin-left: ${rem(5)};
    }

    .finish-links {
        display: none;

        &.hide-on-mobile {
            display: block;
        }

        a {
            display: flex;
            align-items: center;
            margin-top: ${rem(32)};
        }

        .finish-link-icon {
            width: ${rem(30)};
            height: ${rem(30)};
            margin: 0 ${rem(8)} 0 0;

            &.file-icon {
                width: ${rem(23)};
            }

            &.arrow-icon {
                display: none;
            }
        }

        @media (max-width: ${mediumBreakpoint}) {
            display: block;

            &.hide-on-mobile {
                display: none;
            }

            a {
                margin: ${rem(40)} 0;
                justify-content: flex-end;
            }

            .finish-link-icon {
                width: ${rem(16)};
                height: ${rem(16)};
                margin: 0 0 0 ${rem(8)};

                &.file-icon {
                    display: none;
                }

                &.arrow-icon {
                    display: block;
                }
            }

            .print-link {
                display: none;
            }
        }
    }

    .full-breakdown-link {
        .proper-name {
            padding: 0 ${rem(4)};
        }
    }

    .share-deal-save-panel,
    .dealer-deal-save-panel {
        @media (min-width: ${breakpoint}) {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

    .finish-success-container .share-deal-save-panel {
        margin-left: 0;
        margin-right: 0;
    }

    .not-editable {
        .completed-card-flex {
            height: auto;
        }
        .card-title {
            margin-bottom: ${rem(25)};
        }

        .not-editable-icon {
            margin-bottom: ${rem(25)};
        }
    }

    .card-drawer-list-values {
        font-weight: ${({ theme }) => theme.cardDrawerListValuesFontWeight};
    }

    .how-it-works-page {
        .common-header {
            font-size: ${rem(18)};
        }
        margin-top: 4rem;
        h1 {
            margin-top: 0;
        }
        .get-started {
            margin-bottom: 1rem;
        }
        .content-panel {
            margin-bottom: 1rem;
            padding: 1rem;
            border: ${({ theme }) => theme.dashboardCardContainerBorder};
            background-color: ${({ theme }) => theme.dashboardDrActivityDashboardSkinnyCardBackgroundColor};
            box-shadow: ${({ theme }) => theme.dashboardSkinnyCardBoxShadow};
            border-radius: ${({ theme }) => theme.dashboardSkinnyCardBorderRadius};
            @media (min-width: ${breakpoint}) {
                min-height: 150px;
            }
        }
        .back-to-deal {
            font-size: ${rem(18)};
        }

        .how-it-works-logo {
            max-height: 100px;
            margin-bottom: 1rem;
        }

        .video-container {
            position: relative;
        }

        .how-it-works-video {
            width: 100%;
            height: auto;
            background-color: ${({ theme }) => theme.dashboardDrActivityDashboardSkinnyCardBackgroundColor};
            box-shadow: ${({ theme }) => theme.dashboardSkinnyCardBoxShadow};
            border-radius: ${({ theme }) => theme.dashboardSkinnyCardBorderRadius};
            cursor: pointer;
        }

        .play-icon {
            width: 50px;
            height: 50px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -25px;
            margin-top: -25px;
            cursor: pointer;
            pointer-events: none;
        }
    }
`;
