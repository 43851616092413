import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import { InitialDisclaimerDisplay } from '../../types/InitialDisclaimerDisplay';

export interface IPaymentDisclaimerProps {
    fullDisclaimerText?: string;
    shortDisclaimerText?: string;
    showDetailsLink?: boolean;
    disclaimerTextId?: string;
    initialDisclaimerDisplay?: InitialDisclaimerDisplay;
    innerDisclaimerId?: string;
    invertTextColor?: boolean;
    onDetailsLinkClicked?: () => void;
}

const DisclaimerTextContainer = styled.div`
    font-size: ${rem(12)}
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ theme, invertTextColor }) =>
        invertTextColor ? theme.commonPaymentDisclaimerTextColorInverted : theme.commonPaymentDisclaimerTextColor};
    margin-top: 1rem;
    padding: 0 1rem;
`;

const DisclaimerText = styled.div`
    display: inline;
`;

const DisclaimerAnchor = styled.a`
    cursor: pointer;
    margin-left: 1rem;
`;

export const PaymentDisclaimer: React.FC<IPaymentDisclaimerProps> = ({
    disclaimerTextId = 'disclaimer-text',
    initialDisclaimerDisplay = InitialDisclaimerDisplay.SHORT,
    fullDisclaimerText = '',
    showDetailsLink = true,
    shortDisclaimerText = '',
    innerDisclaimerId = 'disclaimer-text-container-inner',
    invertTextColor = false,
    onDetailsLinkClicked
}) => {
    const [showLongDisclaimer, setShowLongDisclaimer] = React.useState(
        initialDisclaimerDisplay === InitialDisclaimerDisplay.SHORT ? false : true
    );

    // Fall back to use provided short or long text if one of the props is undefined or empty
    const disclaimerText: string = showLongDisclaimer
        ? fullDisclaimerText || shortDisclaimerText
        : shortDisclaimerText || fullDisclaimerText;

    const seeDetailsLinkText = showLongDisclaimer ? 'Hide Details' : 'Show Details';
    const disclaimerClickHandler = () => {
        setShowLongDisclaimer(!showLongDisclaimer);

        if (onDetailsLinkClicked) {
            onDetailsLinkClicked();
        }
    };

    const detailsLink = showDetailsLink ? (
        <span onClick={disclaimerClickHandler}>
            <DisclaimerAnchor className="primary-link">{seeDetailsLinkText}</DisclaimerAnchor>
        </span>
    ) : null;

    return (
        <div className="disclaimer-text-container">
            <DisclaimerTextContainer invertTextColor={invertTextColor} id={innerDisclaimerId}>
                <DisclaimerText id={disclaimerTextId}>{disclaimerText}</DisclaimerText>
                {detailsLink}
            </DisclaimerTextContainer>
        </div>
    );
};
