export const Resources = {
    ACCELERATE_DEFAULT_LOGO: 'accelerate-logo-2018.svg',
    ACCELERATE_SELECTED_LOGO: 'accelerate-logo-white.svg',
    AMSI_DEFAULT_LOGO: 'ez-buy-logo-2.svg',
    ATC_MOBILE_LOGO_URL: 'atc_logo.png',
    BANNER: 'banner.svg',
    BLACK_CHECKMARK: 'checkmark-black.svg',
    BLUE_STATE_IMAGE_URL: 'blue-state.svg',
    CALCULATOR_URL: 'calculator.svg',
    CALENDAR_PLUS_SIGN: 'calendar-plus-sign.svg',
    CAR: 'car.svg',
    CAR_CHECK_MARK: 'car-check-mark.svg',
    CAR_DOLLAR_SIGN: 'car-dollar-sign.svg',
    CAR_PRICE: 'CarPrice_Icon.svg',
    CERTIFICATE_IMAGE_URL: 'certificate.svg',
    CHAT_IMAGE_URL: 'chat.svg',
    CHECKERED_FLAG: 'checkered_flag.svg',
    CHECKMARK: 'checkmark.svg',
    CHECKMARK_IMAGE_URL: 'checkmark-complete.svg',
    CHECKMARK_THIN: 'checkmark-thin.svg',
    CHECK_CIRCLE: 'check-circle.svg',
    CHEVRON_LEFT: 'chevron-left.svg',
    CHEVRON_RIGHT: 'chevron-right.svg',
    CLOSE_MINUS_SIGN_IMAGE_URL: 'Closed.svg',
    CLOSE_ICON_HQ: 'close-icon-hq.svg',
    CLOSE_X: 'close-x.svg',
    CONTINUE_IMAGE_URL: 'continue.svg',
    CREDIT_APPLICATION: 'credit-application.svg',
    CREDIT_CARD_IMAGE_URL: 'creditcard.svg',
    CREDIT_IMAGE_URL: 'finance-app.svg',
    DEAL_PENDING_IMAGE_URL: 'deal-pending.svg',
    DEPOSIT_IMAGE_URL: 'deposit-icon.svg',
    DOWN_CARET_OPEN_URL: 'arrow-down.svg',
    EDIT_URL: 'edit.svg',
    ERROR_ICON_URL: 'error-icon.svg',
    EXCLAMATION_CIRCLE: 'exclamation-circle.svg',
    EXPIRES_IMAGE_URL: 'expires.svg',
    HAND_DOLLAR_SIGN: 'hand-dollar-sign.svg',
    HELP_CONTACT_IMAGE_URL: 'help-contact.svg',
    HINT_URL: 'hint.svg',
    HOUSE_DOLLAR_SIGN: 'house-dollar-sign.svg',
    INFO_ICON: 'info.svg',
    INSPECT_TRADE_IMAGE_URL: 'inspect-trade.svg',
    KBB_LOGO: 'kbb.svg',
    KBB_LOGO_URL: 'kbb-logo-notext.png',
    KBB_METER: 'kbb-meter.png',
    KBB_MOBILE_LOGO_URL: 'Kbb_mobile_logo.png',
    LOCK_IMAGE_URL: 'lock.svg',
    MENU_IMAGE_URL: 'menu.svg',
    MINUS_CIRCLE: 'minus-circle.svg',
    MINUS_SQUARE_CORNERS_URL: 'minus-square-corners.svg',
    MINUS_URL: 'minus.svg',
    MONEY_TAG_URL: 'money-tag.svg',
    MY_TERMS_IMAGE_URL: 'my-terms.svg',
    OFFER_IMAGE_URL: 'my-offer.svg',
    OPEN_PLUS_SIGN_IMAGE_URL: 'Open.svg',
    OVAL: 'Oval.svg',
    PAYMENT_TERMS: 'payment-terms.svg',
    PHONE_OPTION_IMAGE_URL: 'phone-option.svg',
    PLAY_IMAGE_URL: 'play.svg',
    PLUS_CIRCLE: 'plus-circle.svg',
    PLUS_SQUARE_CORNERS_URL: 'plus-square-corners.svg',
    PLUS_URL: 'plus.svg',
    PRICE_TAG_URL: 'price-tag.svg',
    QUESTION_MARK_URL: 'question-mark.svg',
    REFUND_IMAGE_URL: 'refund.svg',
    SAVE_DEAL_IMAGE_URL: 'save-deal.svg',
    SKIP_IMAGE_URL: 'skip.svg',
    SMS_IMAGE_URL: 'sms.svg',
    STATUS_DEFAULT_URL: 'status-default.svg',
    STATUS_READY_TO_SEND_URL: 'status-readyToSend.svg',
    STATUS_SENT_URL: 'status-sent.svg',
    TEST_DRIVE_IMAGE_URL: 'test-drive.svg',
    TEST_DRIVE_TWO_IMAGE_URL: 'test-drive-2.svg',
    TRADE_IN_IMAGE_URL: 'trade-in-empty.svg',
    UNLOCK_IMAGE_URL: 'unlock.svg',
    UP_CARET_CLOSE_URL: 'arrow-up.svg',
    VEHICLE_PROTECTION_IMAGE_URL: 'vehicle-protection.svg',
    VEHICLE_PROTECTION_THUMBNAIL_URL: 'default-vehicle-protection-image.png',
    VEHICLE_THUMBNAIL_URL: 'default-vehicle-image.svg',
    VPP_PLACEHOLDER: 'vpp-placeholder.svg',
    CALENDAR: 'calendar.svg',
    KBB_LOGO_HD: 'kbb.png',
    WARNING: 'warning.svg',
    SHARE: 'share.svg',
    SHARE_URL: 'shareUrl.svg',
    EMAIL: 'email.svg',
    TEXT: 'text.svg',

    // p202
    ADDRESS_CARD: 'p202/address-card.svg',
    AMBULANCE: 'p202/ambulance.svg',
    ARROW_RIGHT: 'p202/arrow-right.svg',
    BANK_ICON: 'p202/bank-icon.svg',
    CALCULATOR_BG_ICON: 'p202/calculator-bg-icon.svg',
    CAR_BG_ICON: 'p202/car-bg-icon.svg',
    CAR_MECHANIC: 'p202/car-mechanic.svg',
    CHAT: 'p202/chat.svg',
    CHECK: 'p202/check.svg',
    CHECK_REGULAR: 'p202/check-regular.svg',
    CHEVRON_RIGHT_TALL: 'p202/chevron-right-tall.svg',
    COMPLETED_STEP: 'p202/completed-step.svg',
    DEAL_INFO: 'p202/deal-info.svg',
    FLAG_USA: 'p202/flag-usa.svg',
    FILE_INVOICE_LIGHT: 'p202/file-invoice-light.svg',
    FPO_IMAGE: 'p202/FPO-TradeIn.svg',
    FPO_IMAGE_PNG: 'p202/FPO-TradeIn.png',
    GARAGE_CAR: 'P202/garage-car.svg',
    GLOBE_AMERICAS: 'p202/globe-americas.svg',
    GRADUATION_CAP: 'p202/graduation-cap.svg',
    HAND_HOLDING_CAR: 'p202/hand-holding-car.svg',
    HAND_HOLDING_HEART: 'p202/hand-holding-heart.svg',
    HAND_HOLDING_SEEDLING: 'p202/hand-holding-seedling.svg',
    HAND_OVAL_BACKGROUND: 'p202/hand-oval.svg',
    ID_CARD_ALT: 'p202/id-card-alt.svg',
    MAP_BACKGROUND: 'p202/map-background.png',
    MAP_MARKER: 'p202/map-marker.svg',
    MAP_MARKER_FILLED: 'p202/map-marker-filled.svg',
    MESSAGE: 'p202/message.svg',
    PENCIL_ICON: 'p202/pencil.svg',
    PENCIL_EDIT: 'p202/pencil-edit.svg',
    PIGGY_BANK: 'p202/piggy-bank.svg',
    PHONE: 'p202/phone.svg',
    PRINT_LIGHT: 'p202/print-light.svg',
    SACK_DOLLAR: 'p202/sack-dollar.svg',
    STEERING_WHEEL_BG_ICON: 'p202/steering-wheel-bg-icon.svg',
    TIME_SAVINGS_BG: 'p202/time-savings-bg.svg',
    USERS: 'p202/users.svg',
    VEHICLE_DETAILS: 'p202/vehicle-details.svg',
    LINK: 'link.svg',
    PLAY_CIRCLE: 'play-circle-icon.svg',
    RESERVATIONS_CALENDAR: 'reservations-calendar.svg',
    RESERVATIONS_CAR_TAG: 'reservations-car-tag.svg',
    RESERVATIONS_CREDIT_CARD: 'reservations-credit-card.svg',
    RESERVATIONS_MONEY_BAG: 'reservations-money-bag.svg',
    WORLDPAY: 'worldpay-from-fis-logo-vector.svg',
    APP_ERROR: 'app-error.svg',
    CAR_ANIMATION: 'reservations-loading.gif',
    DOCUMENT_UPLOAD: 'upload.svg',
    GALLERY: 'gallery.svg',
    CAMERA: 'camera.svg',
    SUBARU_GTP_LOGO: 'p202/suburu-gtp-logo.svg'
};
