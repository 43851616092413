import { connect } from 'react-redux';
import AskAQuestionUI from './AskAQuestionUI';
import { handleAskAQuestionLinkClicked } from '../../store/navigation/actionCreators';

const mapStateToProps = (_state, ownProps): any => {
    const alignment = ownProps.alignment || 'center';

    return {
        alignment,
        staticImages: ownProps.staticImages,
        borderDirection: ownProps.borderDirection
    };
};

const mapDispatchToProps = (dispatch: any, _props): any => {
    return {
        onClick: () => {
            dispatch(handleAskAQuestionLinkClicked());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AskAQuestionUI);
