import * as React from 'react';
import ReactDOM from 'react-dom';

interface IModalProps {
    show: boolean;
    elementToBlur: string;
    elementToAttachModalTo: string;
    onClickOutsideModal?: { () };
    onModalShown?: { () };
    onModalHidden?: { () };
    outsideModalExcludeClasses?: string[];
    cls?: string;
}

export const supportsCSSStyle = (cssStyle: string, enableWebkit = false): boolean => {
    const docNode = document as any;
    const element = docNode.createElement('div');
    element.style.cssText = `${enableWebkit ? '-webkit-' : ''}${cssStyle}`;
    return element.style.length !== 0 && (!docNode.documentMode || docNode.documentMode > 9);
};

const getClasses = (cls) => (cls || '').split(' ').filter((token) => token !== '');

export class Modal extends React.Component<IModalProps, {}> {
    browserHasFeature: boolean = supportsCSSStyle('filter:blur(5px)');
    el: HTMLElement = document.createElement('div');
    elToBlur: HTMLElement | null = null;
    elToAttachModalTo: HTMLElement | null = null;

    toggleBlur = () => {
        if (!this.elToBlur) return;
        const { show, onModalShown, onModalHidden, cls } = this.props;

        if (show) {
            this.el.classList.remove('hidden');
            this.elToBlur.classList.add('dr-modal-blur');

            getClasses(cls).forEach((token) => {
                if (this.elToBlur) this.elToBlur.classList.add(token);
                document.body.classList.add(token);
            });

            document.body.classList.add('dr-modal-shown');
            document.addEventListener('click', this.handleDocumentClick, false);
            this.el.children[0].scrollTop = 0;
            if (onModalShown) onModalShown();
        } else {
            this.el.classList.add('hidden');
            this.elToBlur.classList.remove('dr-modal-blur');

            getClasses(cls).forEach((token) => {
                if (this.elToBlur) this.elToBlur.classList.remove(token);
                document.body.classList.remove(token);
            });

            document.body.classList.remove('dr-modal-shown');
            document.removeEventListener('click', this.handleDocumentClick, false);
            if (onModalHidden) onModalHidden();
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) this.toggleBlur();
    }

    componentDidMount() {
        getClasses(this.props.cls).forEach((token) => {
            this.el.classList.add(token);
        });

        this.el.classList.add('hidden');
        this.el.classList.add('dr-modal-parent');
        this.elToAttachModalTo = document.querySelector(this.props.elementToAttachModalTo);
        this.elToBlur = document.querySelector(this.props.elementToBlur);
        this.toggleBlur();

        if (this.elToAttachModalTo !== null) this.elToAttachModalTo.appendChild(this.el);

        if (this.elToBlur && !this.browserHasFeature) {
            this.elToBlur.classList.add('dr-modal-use-opacity');
        }
    }

    componentWillUnmount() {
        if (this.elToAttachModalTo !== null) this.elToAttachModalTo.removeChild(this.el);

        if (this.elToBlur) {
            const { classList } = this.elToBlur;
            classList.remove('dr-modal-blur');
            classList.remove('dr-modal-use-opacity');
        }
        document.removeEventListener('click', this.handleDocumentClick, false);
    }

    handleDocumentClick = (event) => {
        let element = event.target;
        const hasParentNode = element && element.parentNode && element.parentNode.parentNode;
        const targetClasses = ['dr-modal'].concat(this.props.outsideModalExcludeClasses || []);
        while (element) {
            if (targetClasses.find((cls) => element.classList.contains(cls))) {
                return;
            }
            element = element.parentElement;
        }
        hasParentNode && this.props.onClickOutsideModal && this.props.onClickOutsideModal();
    };

    render() {
        const { show, children } = this.props;
        const div = <div className={`dr-modal ${show ? '' : 'hidden'}`}>{children}</div>;
        return ReactDOM.createPortal(div, this.el);
    }
}
