import * as React from 'react';
import {
    QuestionsInputFormUI,
    IQuestionsInputFormDispatchProps,
    IQuestionsInputFormStateProps
} from '../QuestionInputForm/QuestionsInputFormUI';
import TextFieldUI from './../TextField/TextFieldUI';
import { Input } from '@makemydeal/ui-bricks/dist/cox';

export interface IZipUpdaterUIStateProps extends IQuestionsInputFormStateProps {
    answers: any;
    includeTaxes: boolean;
    shopperZip: string;
    pageId: string;
    noMargin: boolean;
    shouldAutoFocus?: boolean;
    usePaymentRedesign?: boolean;
    isP202RedesignEnabled?: boolean;
    disablePaymentCallOnLoad?: boolean;
}

export interface IIsValidZipResult {
    valid: boolean;
}

export interface IZipUpdaterUIDispatchProps extends IQuestionsInputFormDispatchProps {
    getPayment: { (zipCode: string, includeTaxes: boolean): number };
    isValidZip: { (zipCode: string): IIsValidZipResult };
    normalizeZip: { (zipCode: string): string };
    sendZipUpdate: { (isPaymentTermsRedesign: boolean) };
    onForAddTaxesAndFessBtn: { (zipCode: string): void };
    onZipInputUpdate?: { (zipCode: boolean): void };
}

export type ZipUpdaterUIProps = IZipUpdaterUIStateProps & IZipUpdaterUIDispatchProps;

export interface IZipUpdaterUIState {
    valid: boolean;
    zip: string;
    answers: any; // TODO: Define type
}

export class ZipUpdaterUI extends React.Component<ZipUpdaterUIProps, IZipUpdaterUIState> {
    private includeTaxes: boolean;
    private inputRef: any;

    constructor(props) {
        super(props);

        this.state = {
            valid: props.isValidZip(props.shopperZip).valid,
            zip: props.shopperZip,
            answers: props.answers
        };

        this.includeTaxes = props.includeTaxes;
    }

    componentDidMount() {
        const { zip } = this.state;
        const shouldZipUpdateFire: boolean = Boolean(
            this.props.isP202RedesignEnabled && zip.length === 5 && !this.props.disablePaymentCallOnLoad
        );
        if (shouldZipUpdateFire) {
            this.updateZipInput(zip);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isP202RedesignEnabled && prevProps.shopperZip !== this.props.shopperZip) {
            this.setState({ zip: this.props.shopperZip });
        }
    }

    validateZip = (zip): IIsValidZipResult => {
        const result = this.props.isValidZip(zip);
        this.setState({ valid: result.valid });
        return result;
    };

    validateZipAsBool = (zip): boolean => {
        return this.validateZip(zip).valid;
    };

    updateZipInput = (zip) => {
        const zipCode = this.props.normalizeZip(zip);

        if (zipCode !== this.state.zip) {
            this.setState({ zip: zipCode });
        }

        if (this.validateZip(zip).valid) {
            if (zip.length > 0) {
                this.props.getPayment(zip, this.includeTaxes);
            }
            this.props.sendZipUpdate(this.props.usePaymentRedesign || false);
            this.inputRef.blur();
        }

        if (this.props.onZipInputUpdate) this.props.onZipInputUpdate(this.validateZip(zip).valid);
    };

    updateZipInputWrapper = (event) => {
        this.updateZipInput(event.target.value);
    };

    setInputRef = (ref) => {
        this.inputRef = ref;
    };

    renderLegacy() {
        const { noMargin, shouldAutoFocus } = this.props;
        return (
            <div className={`zip-updater ${noMargin ? 'zip-no-margin' : ''}`}>
                <div className="zip-container">
                    <TextFieldUI
                        id="input-shopper-zip"
                        value={`${this.state.zip}`}
                        maxLength={5}
                        validateChange={this.validateZip}
                        updateChange={this.updateZipInput}
                        analyticsId="input-shopper-zip"
                        autofocus={shouldAutoFocus || false}
                        setRef={this.setInputRef}
                        placeholder="Enter ZIP"
                        showMobileNumKeyboard={true}
                        showLabelFilled={true}
                        usePaymentRedesign={this.props.usePaymentRedesign}
                    />
                </div>
                <QuestionsInputFormUI
                    usePaymentRedesign={this.props.usePaymentRedesign}
                    updateLocationId={this.props.updateLocationId}
                    showLocationIdQuestion={this.props.showLocationIdQuestion}
                    locationIdQuestion={this.props.locationIdQuestion}
                    questionsMessage={this.props.questionsMessage}
                    selectedLocationAnswerId={this.props.selectedLocationAnswerId}
                    showLabelFilled={this.props.showLabelFilled}
                />
            </div>
        );
    }

    render() {
        if (!this.props.isP202RedesignEnabled) return this.renderLegacy();

        return (
            <div className="zip-fields">
                <Input
                    inputId="input-shopper-zip"
                    name="input-shopper-zip"
                    label=""
                    maxlength={5}
                    pattern="[0-9]*"
                    inputmode="numeric"
                    ref={this.setInputRef}
                    validationFunction={this.validateZipAsBool}
                    onChangeFunction={this.updateZipInputWrapper}
                    className="input-zip"
                    value={this.state.zip}
                    errorMessage="Please enter a valid zip code"
                />
                <QuestionsInputFormUI
                    usePaymentRedesign={this.props.usePaymentRedesign}
                    updateLocationId={this.props.updateLocationId}
                    showLocationIdQuestion={this.props.showLocationIdQuestion}
                    locationIdQuestion={this.props.locationIdQuestion}
                    questionsMessage={this.props.questionsMessage}
                    selectedLocationAnswerId={this.props.selectedLocationAnswerId}
                    showLabelFilled={this.props.showLabelFilled}
                    isP202RedesignEnabled={this.props.isP202RedesignEnabled}
                />
            </div>
        );
    }
}

export default ZipUpdaterUI;
