import * as React from 'react';

export interface ICardSelectButtonUIProps {
    id?: string;
    headingText?: string;
    secondaryText?: string;
    bodyText?: string;
    onClick: { (event) };
    selected?: boolean;
    panelId?: string;
}

class CardSelectButtonsUI extends React.Component<ICardSelectButtonUIProps> {

    render() {
        const { id, headingText, bodyText, secondaryText, selected, panelId } = this.props
        const selectedButton = selected ? 'card-select-button selected' : 'card-select-button';

        return (
            <div className="card-select-row">
                <button className="card-select-panel" onClick={this.props.onClick} id={panelId} data-id={id}>
                    <div className="card-select-panel-header">
                        <div className="checkbox-ui-container">
                            <label className="condition-header common-header" htmlFor={id}>
                                {headingText}
                            </label>
                        </div>
                        {secondaryText ? <div className="statistic">{secondaryText}</div> : null}
                    </div>
                    <div className="card-select-panel-description">{bodyText}</div>
                    <div className="condition-select-button">
                        <div className={`btn btn-secondary ${selectedButton}`} id={id}>
                            {selected ? 'Selected' : 'Select'}
                        </div>
                    </div>
                </button>
            </div>
        )
    };
}
export default CardSelectButtonsUI;
