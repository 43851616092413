import styled from 'styled-components';
import { rem } from 'polished';

import { Device } from '../../utils/style-util';
import { IconFactory } from '../IconFactory/IconFactory';

export const P202PageUI = styled.div`
    font-family: ${({ theme }) => theme.primaryFontFamily || `'Open Sans', 'Helvetica Neue', sans-serif`};
    margin: 0 auto;
    margin-bottom: ${rem(30)};
    padding: 0 ${rem(20)};
    max-width: ${rem(800)};

    .field-label {
        font-size: ${rem(16)};
        font-weight: ${({ theme }) => theme.commonPageUiFieldLabelFontWeight};
        color: ${({ theme }) => theme.pageFieldLabelColor};
        line-height: 2rem;
        font-family: ${({ theme }) => theme.labelFieldFontFamily};
    }

    > .kbb-footer {
        display: flex;
        margin-top: 3rem;

        .kbb-logo {
            width: 58px;
        }

        .kbb-body-area {
            flex: 1;
            padding: 0 1rem;
        }

        .kbb-title {
            color: ${({ theme }) => theme.kbbTitleColor};
            font-size: rem(14);
            font-weight: ${({ theme }) => theme.commonKbbTitleFontWeight};
        }
        .kbb-body {
            font-size: rem(12);
            color: ${({ theme }) => theme.kbbBodyColor};
        }
    }
    .header-icon,
    .error-div,
    .toggle-expand {
        svg {
            pointer-events: none;
        }
    }
`;

export const StyledIconFactory: any = styled(IconFactory)`
    width: 100%;
    svg g {
        color: ${({ theme }) => theme.headlineFontColor || 'black'};
        fill: currentColor;
    }
    svg image {
        height: ${rem(48)};
        width: ${rem(58)};
    }
`;

export const PageTitle = styled.h2`
    font-size: ${rem(36)};
    color: ${({ theme }) => theme.pageuiTitleColor};
    font-family: ${({ theme }) => theme.headerFontFamily};
    font-weight: ${({ theme }) => theme.headerFontWeight};
    text-transform: ${({ theme }) => theme.headerTextTransform};
    font-size: 1.675rem;
    margin-bottom: ${rem(5)};

    @media ${Device.tablet} {
        font-size: 1.675rem;
    }

    @media ${Device.desktop} {
        font-size: 1.875rem;
    }
`;

export const PageSubTitle = styled.h6`
    font-size: ${rem(18)};
    font-weight: ${({ theme }) => theme.pageuiSubTitleFontWeight};
    margin-top: 0;
    color: ${({ theme }) => theme.pageuiSubTitleColor};
`;

export const TextContainer = styled.div`
    @media ${Device.tablet} {
        padding-left: 0;
    }
`;

export const HeaderLogo = styled.div`
    display: flex;
    color: ${({ theme }) => theme.pageuiHeaderLogoColor};
    fill: currentColor;
    margin-bottom: 2rem;
`;

export const HeaderIcon = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-right: ${rem(18)};
    width: ${rem(72)};
`;

export const PageStepper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;
    position: relative;
`;

export const StepperBarContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    > div {
        flex: 1;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            top: 50%;
            height: 1px;
            background-color: ${({ theme }) => theme.stepperBarBackgroundColor};
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 60%;
            top: 50%;
            height: 1px;
            background-color: rgba(165, 165, 165, 0.7);
        }
        &.full:after {
            width: 100%;
        }
        &.active:after {
            background-color: ${({ theme }) => theme.stepperBarActiveColor || '0d65bf'};
        }
    }
`;

export const PageStepActive = styled.div`
    border-radius: 50%;
    width: ${rem(12)};
    height: ${rem(12)};
    background-color: ${({ theme }) => theme.stepperBarActiveColor || '0d65bf'};
`;

export const BrowserIEWrapper = styled.div`
    .dr-shopper-app .dr-ui-trade-vehicle-info .form-control {
        height: 3rem;
    }
`;

export const PageStepLabel = styled.div`
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.pageuiStepLabelColor};
    position: absolute;
    width: 100px;
    left: -40px;
    text-align: center;
    top: -1.5rem;
    display: none;

    @media ${Device.tablet} {
        display: block;
    }
`;

export const PageStep = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme }) => theme.defaultHrBorderColor || 'black'};
    background-color: ${({ theme }) => theme.pageuiStepBackgroundColor};
    z-index: 1;

    &.active {
        border-color: ${({ theme }) => theme.stepperBarActiveColor || '0d65bf'};
        cursor: pointer;
    }

    &.active.current {
        ${PageStepLabel} {
            display: block;
            font-size: ${rem(14)};
            font-weight: ${({ theme }) => theme.commonPageStepLabelFontWeight};
            color: rgba(0, 0, 0, 0.9);
        }
    }
    &.first {
        ${PageStepLabel} {
            left: 0;
            text-align: left;
        }
    }

    &.last {
        ${PageStepLabel} {
            left: unset;
            right: 0;
            text-align: right;
        }
    }
`;
