import * as React from 'react';

import { IconFactory } from '../IconFactory/IconFactory';
import { IConfigObj } from '@makemydeal/dr-common-utils';
import { BreakPoint } from '../../types/BreakPoint';
import styled from 'styled-components';

const tabletBreakpoint = BreakPoint.SCREEN_SM_MIN;

export const StyledAskAQuestionPanel = styled.div`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: row;
    align-items: flex-start;
    text-align: center;

    width: 100%;
    min-width: 250px;
    min-height: auto;

    padding: 1rem 0;
    margin: 1rem 0;
    position: relative;

    color: #54565a;
    border-color: ${({ theme }) => theme.defaultHrBorderColor};
    border-style: solid;
    border-width: 0;

    @media (min-width: ${tabletBreakpoint}px) {
        flex-direction: column;
        min-height: 224px;
        padding: 1rem 1.25rem;
        margin-top: 1.375rem;
        margin-bottom: 1.375rem;
    }
`;

export const StyledAskAQuestionIconWrapper = styled.div`
    width: 100%;
    text-align: ${({ alignment }) => alignment};
    margin: ${({ alignment }) => (alignment === 'left' ? '0.75rem 1rem' : '0.75rem 0 0 0')};
    flex: 0 1 32px;
    image,
    svg {
        height: 42px;
        width: 32px;
    }
    @media (min-width: ${tabletBreakpoint}px) {
        margin: 0 auto;
    }
`;

export const StyledAskAQuestionPanelContent = styled.div`
    width: 100%;
    flex: 5;
    text-align: ${({ alignment }) => alignment};
`;

export const StyledAskAQuestionHeader = styled.div`
    font-size: 1rem;
    margin-top: 0.35rem;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.commonAskAQuestionHeaderColor};
`;

export const StyledAskAQuestionDescription = styled.div`
    font-size: 0.875rem;
    margin-top: 0;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 1.25rem;
    color: ${({ theme }) => theme.commonAskAQuestionDescriptionColor};
`;

export const StyledAskAQuestionLink = styled.div`
    font-weight: ${({ theme }) => theme.linkTextWeight};
    a {
        &:hover {
            color: ${({ theme }) => theme.primaryLinkHoverColor};
            text-decoration: ${({ theme }) => theme.primaryLinkHoverTextDecoration};
        }
        &:active {
            color: ${({ theme }) => theme.primaryLinkActiveColor};
            text-decoration: ${({ theme }) => theme.primaryLinkActiveTextDecoration};
        }
        &:focus {
            color: ${({ theme }) => theme.primaryLinkFocusColor};
            text-decoration: ${({ theme }) => theme.primaryLinkFocusTextDecoration};
        }
        &:disabled {
            opacity: ${({ theme }) => theme.primaryLinkDisabledOpacity};
        }
    }
`;

export interface BaseAskAQuestionUIProps {
    description: string;
    linkLabel: string;
    onClick: { () };
    alignment: string;
    iconName: string;
    staticImages: IConfigObj;
    outerClassName: string;
    id: string;
    borderDirection?: string;
}

export class BaseAskAQuestionUI extends React.Component<BaseAskAQuestionUIProps, any> {
    applyBorder = (wrapperStyle, dir) => {
        switch (dir) {
            case 'left':
                return (wrapperStyle = { ...wrapperStyle, borderLeftWidth: '1px' });
            case 'right':
                return (wrapperStyle = { ...wrapperStyle, borderRightWidth: '1px' });
            case 'top':
                return (wrapperStyle = { ...wrapperStyle, borderTopWidth: '1px' });
            case 'bottom':
                return (wrapperStyle = { ...wrapperStyle, borderBottomWidth: '1px' });
            default:
                break;
        }
    };

    calcWrapperStyle = () => {
        const { borderDirection, alignment } = this.props;

        let wrapperStyle = {
            borderLeftWidth: '0',
            borderRightWidth: '0',
            borderTopWidth: '0',
            borderBottomWidth: '0',
            flexDirection: 'column'
        };

        const isBorderDirectionArray = Array.isArray(borderDirection);

        if (alignment === 'left') {
            wrapperStyle.flexDirection = 'row';
        }

        if (borderDirection && isBorderDirectionArray) {
            Array.from(borderDirection).forEach((dir) => {
                wrapperStyle = this.applyBorder(wrapperStyle, dir);
            });
        } else if (borderDirection) {
            wrapperStyle = this.applyBorder(wrapperStyle, borderDirection);
        }

        return wrapperStyle;
    };

    render() {
        const wrapperStyle = this.calcWrapperStyle();
        const { staticImages, alignment, description, onClick, linkLabel, iconName, outerClassName, id } = this.props;
        return (
            <StyledAskAQuestionPanel id={id} style={wrapperStyle} className={outerClassName}>
                {staticImages && (
                    <StyledAskAQuestionIconWrapper alignment={alignment}>
                        <IconFactory staticImages={staticImages} icon={iconName} />
                    </StyledAskAQuestionIconWrapper>
                )}
                <StyledAskAQuestionPanelContent alignment={alignment}>
                    <StyledAskAQuestionDescription> {description} </StyledAskAQuestionDescription>
                    <StyledAskAQuestionLink>
                        <a className="primary-link" id="ask_a_question_on_click" onClick={onClick}>
                            {linkLabel}
                        </a>
                    </StyledAskAQuestionLink>
                </StyledAskAQuestionPanelContent>
            </StyledAskAQuestionPanel>
        );
    }
}
