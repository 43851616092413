import * as InlineSVG from 'react-inlinesvg';
import styled from 'styled-components';

const SVG = InlineSVG['default'];

export const StyledSVG = styled(SVG)`
    &.icon-checkRegular {
        g {
            path {
                fill: ${({ theme }) => theme.primaryIconColor};
            }
        }
    }

    &.icon-checkCircle {
        g {
            path {
                fill: ${({ theme }) => theme.defaultCheckmarkColor};
            }
        }
    }

    &.icon-plusCircle {
        g {
            g {
                stroke: ${({ theme }) => theme.primaryIconColor};
            }
        }
    }

    &.icon-minusCircle {
        g {
            g {
                stroke: ${({ theme }) => theme.primaryIconColor};
            }
        }
    }

    &.icon-pencilEdit {
        g {
            path {
                stroke: ${({ theme }) => theme.primaryIconColor};
            }
            text {
                fill: ${({ theme }) => theme.primaryIconColor};
            }
        }
    }

    &.icon-arrowRight {
        svg {
            fill: ${({ theme }) => theme.primaryIconColor};

            path {
                stroke: ${({ theme }) => theme.primaryIconColor};
            }
        }
    }

    &.icon-chevronRight {
        g {
            g {
                fill: ${({ theme }) => theme.primaryIconColor};
            }
        }
    }

    &.icon-chevronLeft {
        g {
            g {
                fill: ${({ theme }) => theme.primaryIconColor};
            }
        }
    }

    &.icon-mapMarkerFilled {
        path:nth-child(1) {
            fill: ${({ theme }) => theme.primaryIconColor};
        }
        path:nth-child(2) {
            fill: ${({ theme }) => theme.primaryIconColor};
        }
    }

    &.icon-houseDollarSign {
        g {
            g {
                fill: ${({ theme }) => theme.commonCardIconColor};
            }
        }
    }

    &.icon-carDollarSign {
        g {
            g {
                fill: ${({ theme }) => theme.commonCardIconColor};
            }
        }
    }

    &.icon-carCheckMark {
        g {
            path {
                fill: ${({ theme }) => theme.commonCardIconColor};
            }
        }
    }

    &.icon-piggyBank {
        path {
            fill: ${({ theme }) => theme.commonCardIconColor};
        }
    }

    &.icon-handHoldingCar {
        path:nth-child(1) {
            fill: ${({ theme }) => theme.commonCardIconColor};
            fill-opacity: 1;
        }
        path:nth-child(2) {
            fill: ${({ theme }) => theme.commonCardIconColor};
            fill-opacity: 1;
        }
    }

    &.icon-pencilIcon {
        path {
            stroke: ${({ theme }) => theme.primaryIconColor};
        }
    }

    &.icon-handDollarSign {
        g {
            g {
                fill: ${({ theme }) => theme.commonCardIconColor};
            }
        }
    }

    &.icon-calendarPlusSign {
        g {
            g {
                fill: ${({ theme }) => theme.commonCardIconColor};
            }
        }
    }

    &.icon-saveDeal {
        g {
            g {
                fill: ${({ theme }) => theme.commonCardIconColor};
            }
        }
    }

    &.inverse-link-icon {
        &.icon-arrowRight {
            svg {
                fill: ${({ theme }) => theme.inverseLinkColor};

                path {
                    stroke: ${({ theme }) => theme.inverseLinkColor};
                }
            }
        }

        &.icon-expand {
            rect {
                fill: ${({ theme }) => theme.inverseLinkColor};
            }
        }
    }

    &.primary-link-icon {
        color: ${({ theme }) => theme.primaryLinkColor};

        &.icon-plusCircle {
            g {
                g {
                    stroke: ${({ theme }) => theme.primaryLinkColor};
                }
            }
        }

        &.icon-minusCircle {
            g {
                g {
                    stroke: ${({ theme }) => theme.primaryLinkColor};
                }
            }
        }

        &.icon-pencilEdit {
            g {
                path {
                    stroke: ${({ theme }) => theme.primaryLinkColor};
                }
                text {
                    fill: ${({ theme }) => theme.primaryLinkColor};
                }
            }
        }

        &.icon-arrowRight {
            svg {
                fill: ${({ theme }) => theme.primaryLinkColor};

                path {
                    stroke: ${({ theme }) => theme.primaryLinkColor};
                }
            }
        }

        &.pencil {
            path {
                stroke: ${({ theme }) => theme.primaryLinkColor};
            }
        }

        &.icon-pencilIcon {
            path {
                stroke: ${({ theme }) => theme.primaryLinkColor};
            }
        }

        &.icon-expand {
            rect {
                fill: ${({ theme }) => theme.primaryLinkColor};
            }
        }
    }

    &.inverse-link-icon {
        &.icon-arrowRight {
            svg {
                fill: ${({ theme }) => theme.inverseLinkColor};

                path {
                    stroke: ${({ theme }) => theme.inverseLinkColor};
                }
            }
        }

        &.icon-expand {
            rect {
                fill: ${({ theme }) => theme.inverseLinkColor};
            }
        }
    }
`;
