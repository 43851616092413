import { NrSpaApiEnum } from '../types/nrSpaApiEnum';

const logNewRelic = (name, value, type: NrSpaApiEnum): void => {
    const nr = typeof window.newrelic === 'object' ? window.newrelic : null;
    const _value = typeof value === 'string' ? value.slice(0, -1) : 'not a valid string';

    if (nr) {
        switch (type) {
            case NrSpaApiEnum.ERROR: {
                nr.noticeError(new Error(`${name} - ${value}`));
                break;
            }
            case NrSpaApiEnum.PAGE_ACTION: {
                nr.addPageAction(name, { name: _value });
                break;
            }
            case NrSpaApiEnum.SET_NAME: {
                nr.interaction().setName(name);
                break;
            }
        }
    }
};

export default logNewRelic;
