export interface USPSCityStateZip {
    zip5: string;
    city: string;
    state: string;
}

export interface USPSServer {
    apiUrl: string;
    userId: string;
}

export const zipUtils = {
    getUspsZipCodeInfo: (zip: string | number, server: USPSServer): Promise<USPSCityStateZip> => {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line max-len
            const url = `${server.apiUrl}&XML=<CityStateLookupRequest USERID="${server.userId}"><ZipCode ID='0'><Zip5>${zip}</Zip5></ZipCode></CityStateLookupRequest>`;
            fetch(url)
                .then((response) => response.text())
                .then((data) => {
                    const dom = new DOMParser().parseFromString(data, 'text/xml');
                    const error = dom.querySelector('Error Description');
                    if (error) {
                        reject(error.innerHTML);
                        return;
                    }

                    const zipCodeData = dom.querySelector('ZipCode')?.children;

                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    const output: USPSCityStateZip = {};

                    if (zipCodeData) {
                        for (let i = 0; i < zipCodeData.length; i++) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                            // @ts-ignore
                            output[zipCodeData.item(i).tagName.toLowerCase()] = zipCodeData.item(i).innerHTML;
                        }
                    }
                    resolve(output);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
};
