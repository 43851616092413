import * as React from 'react';
import SelectUI from '../Select/SelectUI';
import { Select } from '@makemydeal/ui-bricks/dist/cox';

export interface ILocationIdOption {
    displayValue: string;
    id: string;
}

export interface ILocationIdQuestion {
    default: string;
    dependentFields: any[]; // TODO: Define this later
    display: boolean;
    helpText: string;
    id: string;
    options: ILocationIdOption[];
    question: string;
    type: string;
}

export interface IQuestionsInputFormStateProps {
    showLocationIdQuestion: boolean;
    locationIdQuestion: ILocationIdQuestion;
    questionsMessage: string;
    selectedLocationAnswerId: string;
    showLabelFilled?: boolean;
    usePaymentRedesign?: boolean;
    isP202RedesignEnabled?: boolean;
}

export interface IQuestionsInputFormDispatchProps {
    updateLocationId: { (answerId: string) };
}

export type IQuestionsInputFormProps = IQuestionsInputFormStateProps & IQuestionsInputFormDispatchProps;

export class QuestionsInputFormUI extends React.Component<IQuestionsInputFormProps, {}> {
    handleSelection = (selectedId) => {
        this.props.updateLocationId(selectedId);
    };

    handleSelectionWrapper = (event) => {
        const selectedId = event.target.value;
        this.props.updateLocationId(selectedId);
    };

    renderStandardSelect() {
        const { locationIdQuestion, selectedLocationAnswerId } = this.props;
        return (
            <div className="form-row form-group">
                <SelectUI
                    id="location-id__select"
                    selectedOptionId={selectedLocationAnswerId}
                    options={locationIdQuestion.options}
                    onSelectionChange={this.handleSelection}
                />
            </div>
        );
    }

    renderFloatingSelect() {
        const { locationIdQuestion, selectedLocationAnswerId, questionsMessage, usePaymentRedesign } = this.props;
        return (
            <div className="form-row form-group">
                <div className="has-float-label">
                    <SelectUI
                        id="location-id__select"
                        selectedOptionId={selectedLocationAnswerId}
                        options={locationIdQuestion.options}
                        onSelectionChange={this.handleSelection}
                    />
                    {usePaymentRedesign ? '' : <label className="title">{questionsMessage}</label>}
                </div>
            </div>
        );
    }

    renderLegacy() {
        const { showLocationIdQuestion, showLabelFilled, questionsMessage } = this.props;

        if (!showLocationIdQuestion) return null;

        // TODO: optimize to not show empty divs when showLabelFilled = true
        return (
            <div className="questions-form">
                {showLabelFilled ? null : (
                    <div className="questions-message-container">
                        <span>{questionsMessage}</span>
                        <br />
                    </div>
                )}
                {showLabelFilled ? this.renderFloatingSelect() : this.renderStandardSelect()}
            </div>
        );
    }

    renderRedesign() {
        if (!this.props.showLocationIdQuestion) return null;

        const selectItems = this.props.locationIdQuestion.options.map((opt) => (
            <option id={opt.id} key={opt.id} value={opt.id}>
                {opt.displayValue}
            </option>
        ));

        return (
            <Select
                label="Select Location"
                defaultValue=""
                id="location-id__select"
                value={this.props.selectedLocationAnswerId}
                name="location-id__select"
                placeholder="Select"
                onChangeFunction={this.handleSelectionWrapper}
                className="select-zip"
            >
                {selectItems}
            </Select>
        );
    }

    render() {
        if (!this.props.isP202RedesignEnabled) return this.renderLegacy();

        return this.renderRedesign();
    }
}
