import { INavigationState } from '../../types/INavigationState';

export interface IState {
    navigation: INavigationState;
}

export const getNavigation = (state: IState): INavigationState => {
    return state.navigation;
};

export const getRouteIntent = (state: IState): string => {
    return getNavigation(state).routeIntent;
};

export const getHeaderPosition = (state: IState): string | null => {
    return getNavigation(state).headerPosition;
};
