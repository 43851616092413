import * as React from 'react';

import { BaseAskAQuestionUI } from './AskAQuestionComponents';

const description = 'Need to make some changes?';
const linkLabel = 'Ask a Question';
const iconName = 'saveDeal';
const outerClassName = 'ask-a-question-panel';
const id = 'ask-a-question-panel';

const AskAQuestionUI = ({ staticImages, alignment, borderDirection, onClick }) => (
    <BaseAskAQuestionUI
        {...{ staticImages, alignment, onClick, description, linkLabel, iconName, outerClassName, id, borderDirection }}
    />
);

export default AskAQuestionUI;
