/**
 * IconFactory
 * This component allows you to customize any svg asset with DR theme variables
 *
 * How to use it:
 * - Add your icon to the images folder under src
 * - Add an entry for your icon in resources.ts under common
 * - Import your image
 * - Add an entry for your icon on the icons constants
 * - Import IconFactory into your component then:
 *   - Pass the icon you want as defined in the incon constants.
 *   - Set the class name
 * - Add a custom class to customize svg using sass DR theme variables
 *
 * Tip:
 *  - use svgo to optimize your svg assets (TODO: add svgo to webpack)
 */

import * as React from 'react';
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { Resources } from './resources';
import { ResourcesInline } from './resourcesInline';
import { IConfigObj } from '@makemydeal/dr-common-utils';
import { StyledSVG } from './IconFactoryStyles';

let inlineIconIndex = 0;
interface IconFactoryProps {
    icon: string;
    className?: string;
    staticImages: IConfigObj;
}

const icons = {
    accelerateLogo: Resources.ACCELERATE_DEFAULT_LOGO,
    banner: Resources.BANNER,
    blackCheckMark: Resources.BLACK_CHECKMARK,
    blueState: Resources.BLUE_STATE_IMAGE_URL,
    calculator: Resources.CALCULATOR_URL,
    calendar: Resources.CALENDAR,
    calendarPlusSign: Resources.CALENDAR_PLUS_SIGN,
    car: Resources.CAR,
    carCheckMark: Resources.CAR_CHECK_MARK,
    carDollarSign: Resources.CAR_DOLLAR_SIGN,
    carPrice: Resources.CAR_PRICE,
    certificate: Resources.CERTIFICATE_IMAGE_URL,
    chat: Resources.CHAT_IMAGE_URL,
    checkCircle: Resources.CHECK_CIRCLE,
    checkMark: Resources.CHECKMARK,
    checkeredFlag: Resources.CHECKERED_FLAG,
    checkmark: Resources.CHECKMARK_IMAGE_URL,
    checkmarkThin: Resources.CHECKMARK_THIN,
    chevronLeft: Resources.CHEVRON_LEFT,
    chevronRight: Resources.CHEVRON_RIGHT,
    closed: Resources.CLOSE_MINUS_SIGN_IMAGE_URL,
    closeX: Resources.CLOSE_X,
    collapse: Resources.UP_CARET_CLOSE_URL,
    continue: Resources.CONTINUE_IMAGE_URL,
    credit: Resources.CREDIT_IMAGE_URL,
    creditApplication: Resources.CREDIT_APPLICATION,
    creditcard: Resources.CREDIT_CARD_IMAGE_URL,
    dealPending: Resources.DEAL_PENDING_IMAGE_URL,
    deposit: Resources.DEPOSIT_IMAGE_URL,
    edit: Resources.EDIT_URL,
    email: Resources.EMAIL,
    errorIcon: Resources.ERROR_ICON_URL,
    exclamationCircle: Resources.EXCLAMATION_CIRCLE,
    expand: Resources.DOWN_CARET_OPEN_URL,
    expires: Resources.EXPIRES_IMAGE_URL,
    handDollarSign: Resources.HAND_DOLLAR_SIGN,
    helpContact: Resources.HELP_CONTACT_IMAGE_URL,
    hint: Resources.HINT_URL,
    houseDollarSign: Resources.HOUSE_DOLLAR_SIGN,
    info: Resources.INFO_ICON,
    inspectTrade: Resources.INSPECT_TRADE_IMAGE_URL,
    kbb: Resources.KBB_LOGO,
    kbbMeter: Resources.KBB_METER,
    lock: Resources.LOCK_IMAGE_URL,
    menu: Resources.MENU_IMAGE_URL,
    minus: Resources.MINUS_URL,
    minusCircle: Resources.MINUS_CIRCLE,
    minusSquareCorners: Resources.MINUS_SQUARE_CORNERS_URL,
    moneyTag: Resources.MONEY_TAG_URL,
    myTerms: Resources.MY_TERMS_IMAGE_URL,
    open: Resources.OPEN_PLUS_SIGN_IMAGE_URL,
    oval: Resources.OVAL,
    paymentTerms: Resources.PAYMENT_TERMS,
    phoneOption: Resources.PHONE_OPTION_IMAGE_URL,
    play: Resources.PLAY_IMAGE_URL,
    plus: Resources.PLUS_URL,
    plusCircle: Resources.PLUS_CIRCLE,
    plusSquareCorners: Resources.PLUS_SQUARE_CORNERS_URL,
    priceTag: Resources.PRICE_TAG_URL,
    questionMark: Resources.QUESTION_MARK_URL,
    refund: Resources.REFUND_IMAGE_URL,
    saveDeal: Resources.SAVE_DEAL_IMAGE_URL,
    share: Resources.SHARE,
    shareUrl: Resources.SHARE_URL,
    skip: Resources.SKIP_IMAGE_URL,
    sms: Resources.SMS_IMAGE_URL,
    statusDefault: Resources.STATUS_DEFAULT_URL,
    statusReadyToSend: Resources.STATUS_READY_TO_SEND_URL,
    statusSent: Resources.STATUS_SENT_URL,
    testDrive: Resources.TEST_DRIVE_IMAGE_URL,
    testDriveTwo: Resources.TEST_DRIVE_TWO_IMAGE_URL,
    text: Resources.TEXT,
    tradeIn: Resources.TRADE_IN_IMAGE_URL,
    unlock: Resources.UNLOCK_IMAGE_URL,
    vehicleProtection: Resources.VEHICLE_PROTECTION_IMAGE_URL,
    warning: Resources.WARNING,

    // p202
    addressCard: Resources.ADDRESS_CARD,
    ambulance: Resources.AMBULANCE,
    appError: Resources.APP_ERROR,
    arrowRight: Resources.ARROW_RIGHT,
    bank: Resources.BANK_ICON,
    calcBg: Resources.CALCULATOR_BG_ICON,
    camera: Resources.CAMERA,
    carAnimation: Resources.CAR_ANIMATION,
    carBg: Resources.CAR_BG_ICON,
    carMechanic: Resources.CAR_MECHANIC,
    chatIcon: Resources.CHAT,
    check: Resources.CHECK,
    checkRegular: Resources.CHECK_REGULAR,
    chevronRightTall: Resources.CHEVRON_RIGHT_TALL,
    close: Resources.CLOSE_ICON_HQ,
    completedStep: Resources.COMPLETED_STEP,
    dealInfo: Resources.DEAL_INFO,
    fileInvoiceLight: Resources.FILE_INVOICE_LIGHT,
    flagUSA: Resources.FLAG_USA,
    fpoImage: Resources.FPO_IMAGE,
    gallery: Resources.GALLERY,
    garageCar: Resources.GARAGE_CAR,
    globeAmericas: Resources.GLOBE_AMERICAS,
    graduationCap: Resources.GRADUATION_CAP,
    handHoldingCar: Resources.HAND_HOLDING_CAR,
    handHoldingHeart: Resources.HAND_HOLDING_HEART,
    handHoldingSeedling: Resources.HAND_HOLDING_SEEDLING,
    handOvalBackground: Resources.HAND_OVAL_BACKGROUND,
    idCardAlt: Resources.ID_CARD_ALT,
    link: Resources.LINK,
    mapMarker: Resources.MAP_MARKER,
    mapMarkerFilled: Resources.MAP_MARKER_FILLED,
    message: Resources.MESSAGE,
    pencilEdit: Resources.PENCIL_EDIT,
    pencilIcon: Resources.PENCIL_ICON,
    phone: Resources.PHONE,
    piggyBank: Resources.PIGGY_BANK,
    playCircle: Resources.PLAY_CIRCLE,
    printLight: Resources.PRINT_LIGHT,
    reservationsCalendar: Resources.RESERVATIONS_CALENDAR,
    reservationsCarTag: Resources.RESERVATIONS_CAR_TAG,
    reservationsCreditCard: Resources.RESERVATIONS_CREDIT_CARD,
    reservationsMoneyBag: Resources.RESERVATIONS_MONEY_BAG,
    sackDollar: Resources.SACK_DOLLAR,
    steeringWheelBg: Resources.STEERING_WHEEL_BG_ICON,
    timeSavingsBg: Resources.TIME_SAVINGS_BG,
    upload: Resources.DOCUMENT_UPLOAD,
    users: Resources.USERS,
    vehicleDetails: Resources.VEHICLE_DETAILS,
    worldpay: Resources.WORLDPAY,
    subaruGtpLogo: Resources.SUBARU_GTP_LOGO
};

const iconsInline = {
    handDollarSign: ResourcesInline.HAND_DOLLAR_SIGN,
    vehicleDetails: ResourcesInline.VEHICLE_DETAILS,
    collapse: ResourcesInline.UP_CARET_CLOSE_URL,
    expand: ResourcesInline.DOWN_CARET_OPEN_URL,
    arrowRight: ResourcesInline.ARROW_RIGHT,
    plus: ResourcesInline.PLUS_URL,
    info: ResourcesInline.INFO_ICON,
    checkCircle: ResourcesInline.CHECK_CIRCLE,
    plusCircle: ResourcesInline.PLUS_CIRCLE,
    pencilIcon: ResourcesInline.PENCIL_ICON,
    banner: ResourcesInline.BANNER,
    carDollarSign: ResourcesInline.CAR_DOLLAR_SIGN,
    houseDollarSign: ResourcesInline.HOUSE_DOLLAR_SIGN,
    saveDeal: ResourcesInline.SAVE_DEAL_IMAGE_URL,
    calendarPlusSign: ResourcesInline.CALENDAR_PLUS_SIGN,
    carCheckMark: ResourcesInline.CAR_CHECK_MARK,
    checkRegular: ResourcesInline.CHECK_REGULAR,
    fileInvoiceLight: ResourcesInline.FILE_INVOICE_LIGHT,
    handHoldingCar: ResourcesInline.HAND_HOLDING_CAR,
    chevronRight: ResourcesInline.CHEVRON_RIGHT,
    chevronLeft: ResourcesInline.CHEVRON_LEFT,
    questionMark: ResourcesInline.QUESTION_MARK_URL,
    mapMarker: ResourcesInline.MAP_MARKER,
    mapMarkerFilled: ResourcesInline.MAP_MARKER_FILLED,
    piggyBank: ResourcesInline.PIGGY_BANK
};

export const IconFactory: React.FC<IconFactoryProps> = (props) => {
    const { icon, staticImages } = props;
    const className = `${props.className || ''} icon-${icon}`;
    let svgProps = {};
    if (iconsInline[icon]) {
        inlineIconIndex++; // To make each inline icon have a unique ID
        svgProps = {
            src: `data:image/svg+xml;base64,${btoa(iconsInline[icon])}`,
            uniquifyIDs: true,
            uniqueHash: `${icon}-${inlineIconIndex}`
        };
    } else {
        svgProps = {
            src: urlBuilder.buildFromConfig(staticImages, icons[icon]),
            cacheGetRequests: true
        };
    }
    return (
        <>
            <StyledSVG {...svgProps} className={className}></StyledSVG>
        </>
    );
};

IconFactory.defaultProps = {
    className: ''
};
