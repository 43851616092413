import styled from 'styled-components';
import { rem } from 'polished';
import { Device } from '../../utils/style-util';

export const NextStepCardRoot = styled.div`
    flex-basis: 58%;
    box-shadow: ${({ theme }) => theme.commonNextStepsCardBoxShadow};
    border-radius: ${({ theme }) => theme.commonNextStepsCardBorderRadius};
    background-color: white;

    @media ${Device.phone} {
        .next-steps-card {
            margin-bottom: ${rem(25)};
        }
    }
`;
export const RibbonText = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    text-align: center;
    font-size: ${rem(12)};
    width: 100%;
    line-height: 1.1;
    padding-bottom: ${rem(10)};
`;
export const TimeText = styled.div`
    color: ${({ theme }) => theme.nextStepsTimeColor};
    font-size: ${rem(24)};
    font-weight: bold;
`;
export const RibbonContainer = styled.div`
    position: absolute;
    right: 16px;
    top: -10px;

    .next-steps-ribbon {
        svg {
            g {
                use:nth-child(2) {
                    fill: ${({ theme }) => theme.commonNextStepsRibbonFill};
                }
            }
        }
    }
`;
export const NextStepsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-image: ${({ theme }) =>
        `linear-gradient(to left, ${theme.nextStepsBackgroundGradient1}, ${theme.nextStepsBackgroundGradient2})`};
    padding: 0 ${rem(16)};
    height: 53px;
    border-radius: ${({ theme }) => theme.commonNextStepsHeaderBorderRadius};
`;
export const NextStepsHeaderText = styled.span`
    color: ${({ theme }) => theme.nextStepsHeaderTextColor};
    font-size: ${rem(18)};
`;
export const NextStepsBody = styled.div`
    padding: ${rem(24)} ${rem(24)} ${rem(30)} ${rem(24)};
`;
export const NextStepsTitle = styled.div`
    font-size: ${rem(24)};
    color: ${({ theme }) => theme.nextStepsTitleColor};
`;
export const NextStepsDescription = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.nextStepsDescriptionColor};
    padding: ${rem(10)} 0;

    @media (min-width: 670px) {
        font-size: ${rem(16)};
    }
`;
export const NextStepsButton = styled.button`
    width: 55%;
    height: 49px;
    margin-top: ${rem(35)};
    cursor: pointer;
    font-size: ${rem(18)};

    display: flex;
    align-items: center;
    justify-content: center;
`;
export const NextStepsButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    svg {
        height: 50px;
    }
`;
