import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import { IConfigObj } from '@makemydeal/dr-common-utils';

import ActionFooterBar, { IActionFooterBarProps } from '../ActionFooterBar/ActionFooterBar';
import { INextStepCardDetails } from '../NextStepsUI/NextStepCard';
import { NextStepsUI } from '../NextStepsUI/NextStepsUI';
import * as AnalyticsActionTypes from '../../types/AnalyticsActionTypes';
import { DisclaimerPosition } from '../../types/DisclaimerPosition';
import { default as ShareDeal } from './../ShareDeal/ShareDeal';

const DoneButton = styled.a`
    min-width: 12.5rem;
    max-width: 13rem;
    display: block;
    margin: auto;
    margin-top: ${rem(24)};
`;

import {
    BrowserIEWrapper,
    HeaderLogo,
    HeaderIcon,
    TextContainer,
    PageTitle,
    PageSubTitle,
    PageStepper,
    StepperBarContainer,
    PageStep,
    PageStepActive,
    PageStepLabel,
    P202PageUI,
    StyledIconFactory
} from './PageUIComponents';
import DealerDeal from '../ShareDeal/DealerDeal';

export interface IStepperRoutes {
    name: string;
    route: string;
    analyticsId?: string;
}

export interface IPageUIProps {
    disclaimerPosition?: DisclaimerPosition;
    pageClass: string;
    pageTitle: string | JSX.Element;
    pageSubTitle: string | JSX.Element;
    headerIcon?: string;
    headerIconCls?: string;
    footerProps?: IActionFooterBarProps;
    showShareDeal?: boolean;
    isDealerUser?: boolean;
    isDeskingUser?: boolean;
    stepperRoutes?: IStepperRoutes[];
    goToRoute?: { (route: string): void };
    renderError?: { () };
    paymentError?: { () };
    renderDisclaimer?: { (props?) };
    staticAssetsConfig?: IConfigObj;
    urlPath?: string;
    cardDetails?: INextStepCardDetails;
    dispatchAnalytics?: { (type: string, description: string) };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPageUIState {}

export default class PageUI extends React.Component<IPageUIProps, IPageUIState> {
    static defaultProps: Partial<IPageUIProps> = {
        disclaimerPosition: DisclaimerPosition.BELOW_FOOTER_BUTTON
    };

    renderHeader = () => {
        const { pageTitle, pageSubTitle, headerIcon, headerIconCls, staticAssetsConfig } = this.props;
        return (
            <BrowserIEWrapper className="browser-ie">
                <HeaderLogo className="header-logo">
                    {headerIcon && (
                        <HeaderIcon className="hidden-xs header-icon">
                            <StyledIconFactory
                                icon={headerIcon}
                                className={`icon ${headerIconCls}`}
                                staticImages={staticAssetsConfig}
                            />
                        </HeaderIcon>
                    )}
                    <TextContainer className="text-container">
                        {pageTitle && <PageTitle className="page-title common-header">{pageTitle}</PageTitle>}
                        {pageSubTitle && <PageSubTitle className="page-subtitle">{pageSubTitle}</PageSubTitle>}
                    </TextContainer>
                </HeaderLogo>
            </BrowserIEWrapper>
        );
    };

    handleStepperClick = (event: React.MouseEvent) => {
        const nextRoute = event.currentTarget.getAttribute('data-route') || '';
        const analyticsId = event.currentTarget.getAttribute('data-analytics-id') || '';

        if (this.props.dispatchAnalytics) {
            this.props.dispatchAnalytics(
                AnalyticsActionTypes.DR_TRADEIN_STATUSBAR_CLICKED,
                `User clicked on status bar for ${analyticsId}`
            );
        }
        if (this.props.goToRoute) this.props.goToRoute(nextRoute);
    };

    handleDone = () => {
        if (this.props.goToRoute) this.props.goToRoute('/dashboard');
    };

    renderSteps = () => {
        const { stepperRoutes, urlPath } = this.props;
        if (!stepperRoutes) return;
        let current = stepperRoutes.length - 1;

        const steps = stepperRoutes.map(({ name, route, analyticsId }, index) => {
            if (route === urlPath) current = index;
            const activeBullet = index <= current;
            const activeCls = `${activeBullet ? 'active' : ''} ${current === index ? 'current' : ''}`;
            const stepCls = `${index === 0 ? 'first' : ''} ${index === stepperRoutes.length - 1 ? 'last' : ''}`;
            let barCls = `${index < current ? 'full' : ''} ${activeCls}`;
            barCls += ` ${index >= stepperRoutes.length - 1 ? 'hidden' : ''}`;

            return {
                name,
                route,
                analyticsId,
                barCls,
                activeCls,
                stepCls,
                activeBullet
            };
        });

        return (
            <PageStepper className="page-stepper">
                <StepperBarContainer className="stepper-bar-container">
                    {steps.map(({ route, barCls }) => (
                        <div key={route} className={barCls} />
                    ))}
                </StepperBarContainer>
                {steps.map(({ name, route, activeCls, stepCls, activeBullet, analyticsId }, index) => (
                    <PageStep
                        key={route}
                        className={`page-step ${activeCls} ${stepCls}`}
                        data-route={route}
                        data-analytics-id={analyticsId}
                        {...(activeBullet && { onClick: this.handleStepperClick })}
                    >
                        {index <= current && <PageStepActive className="page-step-active" />}
                        <PageStepLabel className="page-step-label">{name}</PageStepLabel>
                    </PageStep>
                ))}
            </PageStepper>
        );
    };

    renderNextSteps() {
        if (!this.props.cardDetails) return null;

        const { staticAssetsConfig, cardDetails, goToRoute, dispatchAnalytics } = this.props;
        const props = {
            cardDetails,
            staticImages: staticAssetsConfig,
            goToRoute,
            dispatchAnalytics
        };
        return <NextStepsUI {...props} />;
    }

    renderShareDeal() {
        const { isDealerUser, isDeskingUser, showShareDeal, staticAssetsConfig } = this.props;
        const props = { staticImages: staticAssetsConfig, borderDirection: 'top' };
        if (isDealerUser) {
            if (isDeskingUser) {
                return (
                    <DoneButton data-id="done-link" className="btn btn-primary" onClick={this.handleDone}>
                        Done
                    </DoneButton>
                );
            } else {
                return <DealerDeal {...props} />;
            }
        } else if (showShareDeal) {
            return <ShareDeal {...props} />;
        } else {
            return null;
        }
    }

    renderDisclaimerAndActionFooterBar(): React.ReactNode {
        const { disclaimerPosition, footerProps, renderDisclaimer } = this.props;
        const actionFooterBar = footerProps && <ActionFooterBar {...footerProps} />;
        const disclaimer = renderDisclaimer && renderDisclaimer();
        switch (disclaimerPosition) {
            case DisclaimerPosition.BELOW_FOOTER_BUTTON:
                return (
                    <React.Fragment>
                        {actionFooterBar}
                        {disclaimer}
                    </React.Fragment>
                );
            case DisclaimerPosition.ABOVE_FOOTER_BUTTON:
                return (
                    <React.Fragment>
                        {disclaimer}
                        {actionFooterBar}
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        {actionFooterBar}
                        {disclaimer}
                    </React.Fragment>
                );
        }
    }

    render() {
        const { children, isDeskingUser, pageClass, renderError, paymentError } = this.props;
        return (
            <P202PageUI className={`dr-p202-page-ui ${pageClass}`}>
                {this.renderSteps()}
                {paymentError && paymentError()}
                {this.renderHeader()}
                {renderError && renderError()}
                {children}
                {isDeskingUser ? null : this.renderNextSteps()}
                {this.renderShareDeal()}
                {this.renderDisclaimerAndActionFooterBar()}
            </P202PageUI>
        );
    }
}
